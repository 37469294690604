// Loading.js
import React from "react";

const Loading = () => {
  return (
    <div className="loading-container">
      <img src="/images/loading.png" alt="logo" />
      <div className="spinner"></div>
    </div>
  );
};

export default Loading;
