import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { TabProvider } from './context/tabContext';
import Login from "./components/Login";
import Adminlogin from "./components/adminlogin";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
// import Dashboard from './components/Dashboard';
import Howitworks from "./components/howitworks";
import Home from "./components/home";
import Nav from "./components/nav";
import Themeals from "./components/themeals";
import Boxes from "./components/boxes";
import Preferences from "./components/preferences";
import Deliver from "./components/deliver";
import Meals from "./components/meals";
import Checkout from "./components/checkout";
import Signup from "./components/signup";
import Footer from "./components/footer";
import AdminDash from "./components/adminDash";
import AllMealsList from "./components/mealsList";
import EditUser from "./components/editUser";
import EditMeal from "./components/editMeal";
import SiteOptions from "./components/siteOptions";
import AddUser from "./components/addUser";
import AddMeal from "./components/addMeal";
import UsersList from "./components/usersList";
import AdminSetMenu from "./components/adminSetMenu";
import PageNotFound from "./components/pagenotfound";
import Mydelivery from "./components/mydelivery";
import Mypreferences from "./components/mypreferences";
import Cancelplan from "./components/cancelplan";
import Accountinfo from "./components/accountinfo";
import Paymentmethods from "./components/paymentmethods";
import Offers from "./components/offers";
import Orderhistory from "./components/orderHistory";
import MyMeals from "./components/mymeals";
import Plansettings from "./components/plansettings";
import Myboxes from "./components/myboxes";
import Deliveries from "./components/adminDelivery";
import Mission from "./components/mission";
import Contact from "./components/contact";
import Summary from "./components/summary";
import Extras from "./components/extras";
import OrderSummary from "./components/orderSummary";
import Prepsummary from "./components/prepsummary";
import DeliveriesSummary from "./components/deliverysummary";
import Drivers from "./components/drivers";
import "bootstrap-icons/font/bootstrap-icons.css";
import PaymentStatus from "./components/Paymentstatus";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/passwordreset";
import Resizeall from "./components/resizeall";

import "./styles/merged.css";

function App() {
  const [formData, setFormData] = useState({
    people: parseInt(0),
    nmeals: parseInt(0),
    total: parseInt(0),
    preferences: [],
    mealSelections: [],
    mealsCount: [],
    email: "",
    password: "",
    fname: "",
    lname: "",
    contact: "",
    addressLine1: "",
    addressLine2: "",
    street: "",
    town: "",
    deliveryDay: "Sunday",
    deliverTimeSlot: "1",
    specialInstructions: "",
    billingadd1: "",
    billingadd2: "",
    billingcity: "",
    billingarea: "",
    billingstreet: "",
    smImages: [],
    smallimgs: [],
    smallimgs1: [],
    smallimgs2: [],
    smallimgs3: [],
    smallimgs4: [],
    extras: [
      {
        extra: "",
        price: "",
        quantity: "",
      },
    ],

    initialPayment: 0,
    balance: 0,
    paymentHistory: [{ amount: 0 }],
    mealsByStartDate: {},
    additionalOrderData: {},
  });

  const [inRegister, setInRegister] = useState(["0"]);

  return (
    <Router>
      <div className="appContainer">
        <Nav />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />{" "}
            {/* Route for Home component */}
            <Route path="/home" element={<Home />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/adminlogin" element={<Adminlogin />} />
            <Route path="/howitworks" element={<Howitworks />} />
            <Route path="/themeals" element={<Themeals />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token" element={<ResetPassword />} />
            <Route path="/resize-all-images" element={<Resizeall />} />
            <Route
              path="/extras"
              element={
                <Extras
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/boxes"
              element={
                <Boxes
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/preferences"
              element={
                <Preferences
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/delivery"
              element={
                <Deliver
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/meals"
              element={
                <Meals
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/checkout"
              element={
                <Checkout
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <Signup
                  formData={formData}
                  setFormData={setFormData}
                  inRegister={inRegister}
                  setInRegister={setInRegister}
                />
              }
            />
            <Route
              path="/summary"
              element={
                <Summary
                  formData={formData}
                  setInRegister={setInRegister}
                  inRegister={inRegister}
                />
              }
            />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/paymentstatus" element={<PaymentStatus />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Plansettings />
                </ProtectedRoute>
              }
            />
            {/* <Route path="/summary" element={
                  <ProtectedRoute>
                    <Summary />
                  </ProtectedRoute>
                } /> */}
            <Route
              path="/mymeals"
              element={
                <ProtectedRoute>
                  <MyMeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypreferences"
              element={
                <ProtectedRoute>
                  <Mypreferences />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myplan"
              element={
                <ProtectedRoute>
                  <Myboxes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mydelivery"
              element={
                <ProtectedRoute>
                  <Mydelivery />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cancelplan"
              element={
                <ProtectedRoute>
                  <Cancelplan />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accountinfo"
              element={
                <ProtectedRoute>
                  <Accountinfo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/paymentmethods"
              element={
                <ProtectedRoute>
                  <Paymentmethods />
                </ProtectedRoute>
              }
            />
            <Route
              path="/offers"
              element={
                <ProtectedRoute>
                  <Offers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orderhistory"
              element={
                <ProtectedRoute>
                  <Orderhistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/adminDash"
              element={
                <ProtectedRouteAdmin>
                  <AdminDash />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/mealsList"
              element={
                <ProtectedRouteAdmin>
                  <AllMealsList />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/editmeals"
              element={
                <ProtectedRouteAdmin>
                  <EditMeal />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/adduser"
              element={
                <ProtectedRouteAdmin>
                  <AddUser />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/addmeal"
              element={
                <ProtectedRouteAdmin>
                  <AddMeal />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/usersList"
              element={
                <ProtectedRouteAdmin>
                  <UsersList />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/setMenu"
              element={
                <ProtectedRouteAdmin>
                  <AdminSetMenu />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/siteOptions"
              element={
                <ProtectedRouteAdmin>
                  <SiteOptions />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/edituser"
              element={
                <ProtectedRouteAdmin>
                  <EditUser />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/deliveries"
              element={
                <ProtectedRouteAdmin>
                  <Deliveries />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/ordersummary"
              element={
                <ProtectedRouteAdmin>
                  <OrderSummary />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/prepsummary"
              element={
                <ProtectedRouteAdmin>
                  <Prepsummary />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/deliverysummary"
              element={
                <ProtectedRouteAdmin>
                  <DeliveriesSummary />
                </ProtectedRouteAdmin>
              }
            />
            <Route
              path="/drivers"
              element={
                <ProtectedRouteAdmin>
                  <Drivers />
                </ProtectedRouteAdmin>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
