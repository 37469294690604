import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';



const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        loading: true,
       
    });



    useEffect(() => {
        const loadUser = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                const { exp } = jwtDecode(token);
                const expirationTime = new Date(exp * 1000);
                if (expirationTime < new Date()) {
                    localStorage.removeItem('token');
                } else {
                    setAuthToken(token);
                    try {
                        const res = await axios.get('/api/auth/me');
                        setAuthState({
                            isAuthenticated: true,
                            user: res.data,
                            loading: false
                        });
                        scheduleTokenRefresh(exp);
                    } catch (err) {
                        setAuthState({
                            isAuthenticated: false,
                            user: null,
                            loading: false
                        });
                    }
                }
            } else {
                setAuthState({
                    isAuthenticated: false,
                    user: null,
                    loading: false
                });
            }
        };

        loadUser();
    }, []);

    const scheduleTokenRefresh = (exp) => {
        const expirationTime = new Date(exp * 1000).getTime();
        const timeout = expirationTime - Date.now() - (60 * 1000); // Refresh 1 minute before expiration
        setTimeout(refreshToken, timeout);
    };

    const refreshToken = async () => {
        try {
            const res = await axios.post('/api/auth/refresh-token');
            const { token } = res.data;
            localStorage.setItem('token', token);
            setAuthToken(token);
            const { exp } = jwtDecode(token);
            scheduleTokenRefresh(exp);
        } catch (err) {
            console.error('Failed to refresh token', err);
            logout();
        }
    };

    const login = async (email, password) => {
        try {
            const res = await axios.post(`/api/auth/login`, { email, password });
            const { token } = res.data;
            localStorage.setItem('token', token);
             setAuthToken(token);
            
            const userRes = await axios.get(`/api/auth/me`)
            const orders = await axios.get(`/api/auth/myorders`);
           
              setAuthState({
                isAuthenticated: true,
                user: userRes.data,
                loading: false
           
            })
            const { exp } = jwtDecode(token);
            scheduleTokenRefresh(exp);
            return { success: true, token: jwtDecode(token) };
        } catch (error) {
            if (error.response) {
                return { success: false, error: error.response.data };
            }
        }
    };


     const adminLogin = async (email, password) => {
        try {
            const res = await axios.post(`/api/auth/adminlogin`, { email, password });
            const { token } = res.data;
            // console.log(res.data)
            localStorage.setItem('token', token);
            setAuthToken(token);
            const { exp } = jwtDecode(token);
            const userRes = await axios.get(`/api/auth/me`);
            setAuthState({
                isAuthenticated: true,
                user: userRes,
                loading: false
            });
            scheduleTokenRefresh(exp);
            return { success: true, token: jwtDecode(token) };
        } catch (error) {
            if (error.response) {
                return { success: false, error: error.response.data };
            }
        }
    };

    const createUser = async (userData) => {
        try {
            const res = await axios.post('/api/auth/createUser', userData);
            // const { token } = res.data;
            // localStorage.setItem('token', token);
           // setAuthToken(token);
            // const userRes = await axios.get('/api/auth/me');
            // setAuthState({
            //     isAuthenticated: true,
            //     user: userRes.data,
            //     loading: false
            // });
            //const { exp } = jwtDecode(token);
            login(userData.email, userData.password);
            // scheduleTokenRefresh(exp);
            return { success: true };
        } catch (err) {
            console.error(err);
        }
    };

    const refreshData = async () => {
        try {
            const res = await axios.get('/api/auth/me');
            setAuthState({
                isAuthenticated: true,
                user: res.data,
                loading: false
            });
        } catch (err) {
            setAuthState({
                isAuthenticated: false,
                user: null,
                loading: false
            });
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthToken(null);
        setAuthState({
            isAuthenticated: false,
            user: null,
            loading: false
        });
    };

    const setAuthToken = token => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    return (
        <AuthContext.Provider value={{ authState, login, createUser, logout, refreshData, adminLogin }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
