import React, { useState, useEffect } from 'react';
import AdminPanel from './adminPanel';
import WeeklyDateRanges from './weeklydaterange';
import axios from 'axios';

const AdminSetMenu = () => {
  const [calRange, setCalRange] = useState(52);
  const [mealsLinks, setMealsLinks] = useState({});
  const [dateused, setDateused] = useState('');
  const [titlesArray, setTitlesArray] = useState([]);
  const [secondListIds, setSecondListIds] = useState([]);
  const [linksArray, setLinksArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      var now = new Date();
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
      const offset = lastSunday.getTimezoneOffset();
      lastSunday = new Date(lastSunday.getTime() - (offset * 60 * 1000));
      lastSunday = lastSunday.toISOString().split('T')[0];
      
      await fetchMeals(lastSunday);
      setDateused(lastSunday);
      fetchAllmeals();
    };

    initialize();
  }, []);

  const fetchAllmeals = async () => {
    try {
      const res = await axios.get('/api/auth/allmeals');
      setTitlesArray(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMeals = async (dateused, index) => {
    setDateused(dateused);
    const usedIndex = index === undefined ? 0 : index;

    if (selectedIndex.includes(index)) {
      const filteredArray = linksArray.filter(item => item.dateUsed === dateused);
      const newArray = filteredArray.map(item => item.id);
      setSecondListIds(newArray);
      return;
    }

    setSelectedIndex([...selectedIndex, usedIndex]);

    try {
      const response = await axios.get('/api/auth/mealLinks', { params: { dateUsed: dateused } });
      setSecondListIds(response.data.map(item => item.id));

      const linksData = response.data.map(item => ({ id: item.id, dateUsed: item.dateUsed }));
      if (linksArray && linksArray.length > 0) {
        setLinksArray(prevLinksArray => [...prevLinksArray, ...linksData]);
      } else {
        setLinksArray(linksData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const moveToSecondList = (id) => {
    setSecondListIds(prevData => [...prevData, id]);
    const linksData = { id, dateUsed: dateused };
    setLinksArray([...linksArray, linksData]);
    setMealsLinks(prevData => ({ ...prevData, id, dateUsed: dateused }));
  };

  const moveToFirstList = (_id) => {
    const index = linksArray.findIndex(link => link.id === _id && link.dateUsed === dateused);
    if (index !== -1) {
      const updatedLinksArray = [...linksArray.slice(0, index), ...linksArray.slice(index + 1)];
      setLinksArray(updatedLinksArray);
    }

    setSecondListIds(prevIds => prevIds.filter(secondId => secondId !== _id));
  };

  const handleSave = async () => {
    setLoader(true);
    try {
      await axios.post('api/auth/deleteLinks', {});
      saveLinks();
    } catch (error) {
      console.error(error);
    }
  };

  const saveLinks = async () => {
    try {
      await axios.post('api/auth/setmenu', linksArray);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const firstListItems = titlesArray.filter(item => !secondListIds.includes(item._id));
  const secondListItems = titlesArray.filter(item => secondListIds.includes(item._id));

  return (
    <div className='adminDash'>
      <h1>Set Menu</h1>
      <AdminPanel />
      <div className='adminMainContent'>
        <div className='twocolGrid'>
          <div className='themeals'>
            <h3>Click to add meal to date</h3>
            {firstListItems
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
              .map(themeal => (
                <div key={themeal._id} className={themeal.classic ? 'themeal classic' : 'themeal'} onClick={() => moveToSecondList(themeal._id)}>
                  {themeal.title}
                </div>
              ))}
          </div>
          <div className='weekRange'>
            <WeeklyDateRanges calRange={calRange} onRequest={fetchMeals} />
          </div>
          <div className='menuItems'>
            <h3>Click to remove meal from date</h3>
            {secondListItems
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
              .map((match, index) => (
                <div className={match.classic ? 'classicP classic' : 'currentP'} key={match._id} onClick={() => moveToFirstList(match._id)}>
                  <div className='current' key={index}>
                    {match.title}
                  </div>
                </div>
              ))}
          </div>
          <button onClick={handleSave}>{loader ? 'Saving...' : 'Save'}</button>
        </div>
      </div>
    </div>
  );
};

  

export default AdminSetMenu;
