import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./loading";

const PaymentStatus = () => {
  const location = useLocation();
  const [successValue, setSuccessValue] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const successValue = params.get("success");
    const orderId = params.get("order");
    setSuccessValue(successValue === "true");
    setOrderId(orderId);
  }, [location.search]);

  useEffect(() => {
    if (orderId && successValue !== null) {
      handleOrderProcessing(orderId, successValue);
    }
  }, [orderId, successValue]);

  const handleOrderProcessing = async (orderId, success) => {
    try {
      const response = await axios.post("/api/auth/processOrder", {
        orderId,
        success,
      });
      setMessage(response.data.message);
      navigate("/login");
    } catch (error) {
      console.error("Error processing order:", error);
      setMessage("Error processing order");
    }
  };

  return (
    <>
      {/* {message && <p>{message}</p>} */}
      {successValue !== null ? (
        successValue ? (
          <div className="containerForm payment">
            <Loading />
            {/* <h1>Payment Status</h1> */}
            {/* <div className="flex paySuccess">
              <h1>Payment Successful</h1>
              <h2>
                Thank you for joining us.<Link to="/login"> Login </Link> to
                view your Dashboard to view your meals. Click the button below
                to go to your dashboard.
              </h2>
            </div> */}
          </div>
        ) : (
          <div className="containerForm payment">
            <div className="flex paySuccess">
              <h1>Payment Unsuccessful</h1>
              <h2>
                Thank you for wanting to join. Unfortunately, the payment has
                not been successful.
              </h2>
            </div>
          </div>
        )
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default PaymentStatus;
