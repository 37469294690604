import React from "react";
import axios from "axios";

export const Resizeall = () => {
  const resizeImages = async () => {
    try {
      await axios.post("/api/auth/resizeallimages");
    } catch (error) {
      console.error("Error resizing images:", error);
    }
  };

  return (
    <div>
      <h2>Resize All Images</h2>
      <button onClick={resizeImages}>Resize All</button>
    </div>
  );
};

export default Resizeall;
