import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import AdminPanel from './adminPanel';

function OrderSummary() {
  const [mealsByDeliveryDay, setMealsByDeliveryDay] = useState({});
  const [orders, setOrders] = useState([]);
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    document.title = "Order Summary";
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersRes = await axios.get('/api/auth/orders');
        const mealsRes = await axios.get('/api/auth/allmeals');
        setOrders(ordersRes.data.orders);
        setMeals(mealsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const groupOrdersByDate = (orders, meals) => {
    const grouped = {};
    orders.forEach(order => {
      order.mealsByStartDate.forEach(mealDate => {
        const date = mealDate.deliveryDate;
        if (!grouped[date]) {
          grouped[date] = {};
        }
        mealDate.mealSelections.forEach(mealId => {
          const meal = meals.find(m => m._id === mealId);
          if (meal) {
            if (!grouped[date][meal.title]) {
              grouped[date][meal.title] = {
                totalServings: 0,
                weights: {},
              };
            }
            const servings = mealDate.servings;
            grouped[date][meal.title].totalServings += servings;

            meal.ingredients.forEach(ingredient => {
              const { measurementType, quantity } = ingredient;
              if (!grouped[date][meal.title].weights[measurementType]) {
                grouped[date][meal.title].weights[measurementType] = 0;
              }
              grouped[date][meal.title].weights[measurementType] += quantity * servings;
            });
          }
        });
      });
    });
    return grouped;
  };

  const groupedOrders = groupOrdersByDate(orders, meals);

  const convertToCSV = (data) => {
    const headers = [
      'Meal Title',
      'Total Servings',
      'Total Pieces',
      'Total Grams',
      'Total Kilograms',
    ];
    const csvRows = [headers.join(',')];
    Object.keys(data).forEach(day => {
      Object.keys(data[day]).forEach(mealTitle => {
        const meal = data[day][mealTitle];
        const row = [
          mealTitle,
          meal.totalServings,
          meal.weights.pieces || 0,
          meal.weights.grams || 0,
          meal.weights.kilograms || 0,
        ];
        csvRows.push(row.join(','));
      });
    });
    return csvRows.join('\n');
  };

  const downloadCSV = (day) => {
    const csvData = convertToCSV({ [day]: groupedOrders[day] });
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${day}_meals.csv`);
    a.click();
  };

  const downloadPDF = (day) => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Order Summary for ${day}`, 10, 10);
    let y = 20;
    Object.keys(groupedOrders[day]).forEach(mealTitle => {
      const meal = groupedOrders[day][mealTitle];
      const mealText = `
        Meal: ${mealTitle}
        Total Servings: ${meal.totalServings}
        Pieces: ${meal.weights.pieces || 0}
        Grams: ${meal.weights.grams || 0}
        Kilograms: ${meal.weights.kilograms || 0}
      `;
      doc.text(mealText, 15, y);
      y += 30;
    });
    doc.save(`${day}_meals.pdf`);
  };

  return (
    <div className='adminDash'>
      <h1>Order Summary</h1>
      <AdminPanel />
      <div className='adminMainContent'>
        <div className='dashboard orderSummary'>
          <div>
            {Object.keys(groupedOrders).map(date => (
              <div key={date} className='wkdeliveryDayS'>
                <h2>Date: {date}</h2>
                <div className='summarybtn'>
                  <button onClick={() => downloadCSV(date)}>Download CSV</button>
                  <button onClick={() => downloadPDF(date)}>Download PDF</button>
                </div>
                <table className='orderTable'>
                  <thead>
                    <tr>
                      <th>Meal Title</th>
                      <th>Total Servings</th>
                      <th>Total Pieces</th>
                      <th>Total Grams</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupedOrders[date]).map(mealTitle => (
                      <tr key={mealTitle}>
                        <td>{mealTitle}</td>
                        <td>{groupedOrders[date][mealTitle].totalServings}</td>
                        <td>{groupedOrders[date][mealTitle].weights.pieces || 0}</td>
                        <td>{groupedOrders[date][mealTitle].weights.grams || 0}</td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary