import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import WeeklyDateRanges from "../components/weeklydaterange";
import { AuthContext } from "../context/AuthContext";
import "../styles/meals.css";
import Loading from "./loading";
import MealModal from "./mealModal"; // Import the MealModal component

const MyMeals = () => {
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [pricepermeal, setPricepermeal] = useState(50);
  const [gourmetPrice, setGourmetPrice] = useState(10);
  const [people, setPeople] = useState(authState.user.noPeople);
  const location = useLocation();
  const [MealsList, setMealsList] = useState([]);
  const [keys, setKeys] = useState([]);
  const [mealsCount, setMealsCount] = useState(0);
  const [filteredMealsList, setFilteredMealsList] = useState([]);
  const preferences = authState.user.mealPreferences;
  const meals = authState.user.noMeals;
  const [values, setValues] = useState([]);
  const userId = authState.user._id;
  const [mealSelections, setMealSelections] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateused, setDateused] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null); // Change state to store selected meal object
  const [mealSelectionsByDate, setMealSelectionsByDate] = useState({});
  const [currentSelections, setCurrentSelections] = useState([]);
  const [mealCounts, setMealCounts] = useState({});
  const [lastSunday, setLastSunday] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [orderCost, setOrderCost] = useState(0);
  const [totalorderCost, setTotalOrderCost] = useState(0);
  const [origTotalCost, setOrigTotalCost] = useState(0);

  const filters = [
    "family friendly",
    "kid friendly",
    "low carb",
    "vegetarian",
    "pescatarian",
    "calorie smart",
    "classics",
    "guilt free",
    "chicken",
    "meat",
    "gourmet",
    "breakfast",
    "clear filter",
  ];
  const [orderData, setOrderData] = useState({
    mealsByStartDate: [],
    totalPrices: {},
  });
  const [smallimgs, setSmallimgs] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const buttonsRef = useRef([]);

  // const [smallimgs1, setSmallimgs1]= useState([])
  // const [smallimgs2, setSmallimgs2]= useState([])
  // const [smallimgs3, setSmallimgs3]= useState(formData.smallimgs3 || [])
  // const [smallimgs4, setSmallimgs4]= useState(formData.smallimgs4 || [])

  const counts = {};
  const { state } = location;

  const navigate = useNavigate();

  const dialogRef = useRef();
  const toggleDialog = async function (id) {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.classList.add("closing")
      : dialogRef.current.classList.remove("closing");
    dialogRef.current.hasAttribute("open")
      ? document.body.classList.remove("hideOverflow")
      : document.body.classList.add("hideOverflow");
    dialogRef.current.hasAttribute("open")
      ? dialogRef.current.close()
      : dialogRef.current.showModal();

    if (dialogRef.current.hasAttribute("open")) {
    }
  };
  const handleClick = async (e) => {
    toggleDialog();
    setLoading(true);
    e.preventDefault();

    // Build the payload to match the schema
    // const mealsByStartDate = Object.entries(mealSelectionsByDate).map(
    //   ([weekstarting, mealSelections]) => ({
    //     weekstarting,
    //     mealSelections,
    //   })
    // );

    // console.log(mealsByStartDate);

    try {
      const response = await axios
        .put(`/api/auth/updateUserMealSelections/${userId}`, {
          currentSelections,
          orderTotal: orderCost,
        })
        .then((response) => {
          setUpdateMessage(response.data.message);
          setLoading(false);
        });
    } catch (error) {
      console.error(`Error updating meal selections:`, error);
      setLoading(false); // Ensure loading state is turned off on error as well
    }
  };

  useEffect(() => {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
    const offset = lastSunday.getTimezoneOffset();
    lastSunday = new Date(lastSunday.getTime() - offset * 60 * 1000);
    lastSunday = lastSunday.toISOString().split("T")[0];
    setLastSunday(lastSunday);

    const fetchMeals = async (dateused) => {
      try {
        const response = await axios
          .get(`/api/auth/newMeals`, {
            params: {
              dateUsed: dateused,
            },
          })
          .then((res) => {
            const mealsData = res.data.mergedResults;
            const prefLength = preferences.length;

            // console.log(prefLength);

            switch (prefLength) {
              case 1:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  a.mealtype[0].includes(preferences[0])
                    ? -1
                    : 1
                );
                break;
              case 2:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]))
                    ? -1
                    : 1
                );
                break;
              case 3:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]))
                    ? -1
                    : 1
                );
                break;
              case 4:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]) ||
                    a.mealtype[0].includes(preferences[3]))
                    ? -1
                    : 1
                );
                break;
              case 5:
                mealsData.sort((a, b) =>
                  a.mealtype &&
                  a.mealtype[0] &&
                  (a.mealtype[0].includes(preferences[0]) ||
                    a.mealtype[0].includes(preferences[1]) ||
                    a.mealtype[0].includes(preferences[2]) ||
                    a.mealtype[0].includes(preferences[3]) ||
                    a.mealtype[0].includes(preferences[4]))
                    ? -1
                    : 1
                );
                break;
              default:
                break;
            }

            setMealsList(mealsData);
            setFilteredMealsList(mealsData);
          });
      } catch (error) {
        console.log(error);
      }
    };

    const fetchOrders = async (dateUsed) => {
      try {
        const response = await axios
          .get("/api/auth/myorders", {
            params: { id: userId, dateUsed: lastSunday },
          })
          .then((response) => {
            // setMealSelections((prevSelections) => ({
            //   ...prevSelections,
            //   [dateUsed]: response.data,
            // }));

            // Update the state with the meal selections

            // setMealSelectionsByDate((prevSelections) => ({
            //   ...prevSelections,
            //   [lastSunday]: response.data,
            // }));
            // console.log(response.data);
            console.log(response.data);
            setOrderCost(() => ({ [dateUsed]: response.data.orderTotal })); // Set the order cost for this date

            setTotalOrderCost(response.data.orderTotal);
            setOrigTotalCost(response.data.totalOrderCost);

            const selections = response.data.mealSelections.map((item) => ({
              meal: item.meal,
              count: item.count,
            }));
            setCurrentSelections(() => ({
              [dateUsed]: selections, // Set the transformed data for this date
            }));

            // const calculateSumForDate = (selections, date) => {
            //   const dateSelections = selections[date] || [];
            //   console.log("Date Selections:", dateSelections);
            //   return dateSelections.reduce(
            //     (sum, selection) => sum + selection.count,
            //     0
            //   );
            // };

            // const date = lastSunday;
            // const totalCount = calculateSumForDate(currentSelections, date);
            // setValues(totalCount);
            // console.log(`Total count for ${date}: ${totalCount}`);

            // setMealsCount(totalCount);
            // console.log(`Total count for ${date}: ${totalCount}`);
            setValues(response.data.mealSelections);

            //   if (values.length > 0) {
            //     values.forEach(function (x) {
            //       counts[x] = (counts[x] || 0) + 1;
            //     });
            //   }
          });
      } catch (error) {
        console.log(error);
      }
    };

    fetchMeals(lastSunday);
    fetchOrders(lastSunday);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (lastSunday) {
      const dateSelections = currentSelections[lastSunday] || [];
      const totalCount = dateSelections.reduce(
        (sum, selection) => sum + selection.count,
        0
      );
      setTotalCount(totalCount);
      // console.log(`Total count for ${lastSunday}: ${totalCount}`);
    }
  }, [lastSunday, currentSelections]);

  useEffect(() => {
    console.log(currentSelections);
    console.log(orderCost);
    console.log(origTotalCost);
    console.log(totalorderCost);
  }, [currentSelections, orderCost, origTotalCost, totalorderCost]);

  const handleGetRequest = async (dateUsed) => {
    setLoading(true);
    setLastSunday(dateUsed);

    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: { dateUsed: dateUsed },
      });
      const mealsData = response.data.mergedResults;
      setMealsList(mealsData);
      setFilteredMealsList(mealsData);

      const ordersResponse = await axios.get("/api/auth/myorders", {
        params: { id: userId, dateUsed: dateUsed },
      });

      // setMealSelectionsByDate((prevSelections) => ({
      //   ...prevSelections,
      //   [dateUsed]: ordersResponse.data,
      // }));
      const selections = ordersResponse.data.mealSelections.map((item) => ({
        meal: item.meal,
        count: item.count,
      }));
      setCurrentSelections((prevSelections) => ({
        ...prevSelections,
        [dateUsed]: selections, // Set the transformed data for this date
      }));

      setValues(ordersResponse.data);

      setOrderCost((prevordercost) => ({
        ...prevordercost,
        [dateUsed]: ordersResponse.data.orderTotal,
      })); // Set the order cost for this date

      console.log("Order Cost:", ordersResponse.data.orderTotal);

      setTotalOrderCost(totalorderCost + ordersResponse.data.orderTotal);
      console.log("Total Order Cost:", totalorderCost);
      if (values.length > 0) {
        values.forEach(function (x) {
          counts[x] = (counts[x] || 0) + 1;
        });
      }
    } catch (error) {
      console.log("Error fetching meals or orders:", error);
    }
    //  }
    setLoading(false);
  };

  const handleSelectionAdd = (event, mealId, lastSunday) => {
    event.stopPropagation();
    console.log(mealId, lastSunday);

    setCurrentSelections((prevSelections) => {
      const selectionsForDate = prevSelections[lastSunday] || [];
      const existingSelection = selectionsForDate.find(
        (selection) => selection.meal === mealId
      );
      let updatedSelections;
      if (existingSelection) {
        updatedSelections = selectionsForDate.map((selection) =>
          selection.meal === mealId
            ? {
                ...selection,
                count: selection.count + 1,
              }
            : selection
        );
      } else {
        updatedSelections = [...selectionsForDate, { meal: mealId, count: 1 }];
      }
      const newOrderTotal = calculateOrderTotal(updatedSelections);
      setOrderCost((prevCosts) => ({
        ...prevCosts,
        [lastSunday]: newOrderTotal,
      }));
      return { ...prevSelections, [lastSunday]: updatedSelections };
    });

    if (mealsCount < meals) {
      setMealsCount((prevState) => prevState + 1);
      let counter = event.currentTarget
        .closest(".mealCard")
        .querySelector(".counter");
      let button = event.currentTarget
        .closest(".mealCard")
        .querySelector("button");
      counter.innerText = parseInt(counter.innerText) + 1;
      button.classList.remove("show");
      button.classList.add("hide");

      event.currentTarget.closest(".mealCard").classList.add("addactive");
    }
  };

  const calculateOrderTotal = (selections) => {
    let total = 0;
    selections.forEach((selection) => {
      const meal = MealsList.find((meal) => meal._id === selection.meal);
      const isGourmet = meal && meal.mealtype.includes("gourmet");
      total +=
        selection.count * (pricepermeal + (isGourmet ? gourmetPrice : 0));
    });
    console.log("Total:", people * total);
    console.log("Total total:", totalorderCost + people * total);
    return people * total;
  };

  const handleSelectionRemove = (event, mealId, lastSunday) => {
    event.stopPropagation();
    console.log(mealId, lastSunday);

    setCurrentSelections((prevSelections) => {
      const selectionsForDate = prevSelections[lastSunday] || [];
      const updatedSelections = selectionsForDate
        .map((selection) =>
          selection.meal === mealId
            ? {
                ...selection,
                count: selection.count - 1,
              }
            : selection
        )
        .filter((selection) => selection.count > 0);

      const newOrderTotal = calculateOrderTotalR(updatedSelections);
      setOrderCost((prevCosts) => ({
        ...prevCosts,
        [lastSunday]: newOrderTotal,
      }));
      return { ...prevSelections, [lastSunday]: updatedSelections };
    });

    if (mealsCount > 0) {
      setMealsCount((prevState) => prevState - 1);
      let counter = event.currentTarget
        .closest(".mealCard")
        .querySelector(".counter");
      let button = event.currentTarget
        .closest(".mealCard")
        .querySelector("button");
      counter.innerText = parseInt(counter.innerText) - 1;
      if (parseInt(counter.innerText) === 0) {
        button.classList.remove("hide");
        button.classList.add("show");
        event.currentTarget.closest(".mealCard").classList.remove("addactive");
      }
    }
  };

  const calculateOrderTotalR = (selections) => {
    let total = 0;
    selections.forEach((selection) => {
      const meal = MealsList.find((meal) => meal._id === selection.meal);
      const isGourmet = meal && meal.mealtype.includes("gourmet");
      total -=
        selection.count * (pricepermeal + (isGourmet ? gourmetPrice : 0));
    });
    console.log("Total:", people * total);
    return people * total;
  };

  const handleFilter = (selectedCategory) => {
    if (selectedCategory === "clear filter") {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(selectedCategory)) {
        let filters = selectedFilters.filter((el) => el !== selectedCategory);
        setSelectedFilters(filters);
      } else {
        setSelectedFilters([...selectedFilters, selectedCategory]);
      }
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters, filteredMealsList]);

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = filteredMealsList.filter((item) =>
          item.mealtype.some((type) => type.includes(selectedCategory))
        );
        console.log(temp);
        return temp;
      });

      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));

        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
    } else {
      setMealsList(filteredMealsList);
    }
  };

  // const getMealCount = (values) => {
  //   return values.reduce((acc, mealId) => {
  //     acc[mealId] = (acc[mealId] || 0) + 1;

  //     return acc;
  //   }, {});
  // };

  // const mealCountsx = getMealCount(values);

  const containsGourmet = (mealtype) => {
    if (Array.isArray(mealtype) && mealtype.length > 0) {
      // Split the first element of the array by commas and trim whitespace
      const typesArray = mealtype[0].split(",").map((item) => item.trim());
      // Check if 'gourmet' is in the resulting array
      return typesArray.includes("gourmet");
    }
    return false;
  };

  useEffect(() => {
    document.title = "My Meals";
    setSelectedDate(state);

    setTimeout(() => {
      const buttons = document.querySelectorAll(".addMeal");
      buttonsRef.current = Array.from(buttons);

      if (buttons && buttons.length > 0) {
        if (totalCount === meals) {
          buttons.forEach((button) => {
            if (button) {
              button.classList.add("disabled");
            }
          });
        } else {
          buttons.forEach((button) => {
            if (button) {
              button.classList.remove("disabled");
            }
          });
        }
      }
    }, 150);
  }, [totalCount, meals, MealsList, selectedDate]);

  return (
    <>
      <div className="dashboardContainer">
        <div className="userContainer">
          {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
          <div className="userData container ">
            <div className="userDashboardAdmin">
              <div className="headerSection">
                <h1>Select Meals</h1>
                <h2>Change or select your Meals</h2>
                <Link to={"/dashboard"}>
                  <button className="btd">Back to dashboard</button>
                </Link>
              </div>

              <div className="mealsList">
                {/* <div className="mealImages">
     
<ul className="chosenmeals">
  
  {smallimgs.map((img, index) => (
  <li className="chosenmeal" key={index} data-index={index}><img src={img} alt="image" /></li>
))}
 {Array.from({ length: emptySlots }, (_, index) => (
          <li className="chosenmeal"  key={smallimgs.length + index}></li>
        ))}



</ul>
<div className='selectedTextDiv'>
<p className='selectedText'>You have selected {mealsCount} meals</p>

{noMeals < 5 ? <p className="addmeals" onClick={addMeals}>Add meal</p>: ''}
{/* {noMeals > 3 ? <p onClick={reduceMeals}>Remove meal</p>: ''} */}
                {/* <p>{noMeals}</p> */}
                {/* </div> */}
                {/* <button className="nextButton" onClick={handleNext}>Next</button> */}
                {/* </div>  */}
                <div className="datesContainer">
                  <WeeklyDateRanges onRequest={handleGetRequest} />
                </div>

                <div className="filterContainer">
                  <ul className="filters">
                    {filters.map((category, idx) => (
                      <li
                        className={`filterbutton ${category.replace(
                          / /g,
                          ""
                        )} ${
                          selectedFilters?.includes(category) ? "active" : ""
                        } `}
                        key={`filters-${idx}`}
                        onClick={() => handleFilter(category)}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mealList">
                  {loading ? (
                    <Loading />
                  ) : (
                    MealsList.map((meal, index) => {
                      const baseName = meal.image.split(".")[0]; // Extract base name from image file name
                      const ext = meal.image.split(".")[1]; // Extract extension from image file name

                      return (
                        <div
                          key={meal._id}
                          data-date={meal.weekstarting}
                          id={meal._id}
                          className={`mealCard ${
                            (currentSelections[lastSunday] || []).some(
                              (selection) => selection.meal === meal._id
                            )
                              ? "addactive"
                              : ""
                          }
      ${containsGourmet(meal.mealtype) ? "gourmet" : ""}  ${
                            activeIndex === index ? "expanded" : ""
                          }`}
                          onClick={() => {
                            setSelectedMeal(meal);
                          }}
                        >
                          {/* <p>{meal._id}</p> */}
                          <div className="imgContainer">
                            <img
                              src={`/images/meals/${baseName}-x.${ext}`} // Default to large image
                              alt={meal.title}
                            />
                          </div>
                          <div className="contentContainer">
                            <div className="mealDetails">
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                            </div>
                            <div className="mealtypes">
                              <ul>
                                {meal.mealtype
                                  .toString()
                                  .split(",")
                                  .map((type, index) => (
                                    <li
                                      key={index}
                                      className={type.replace(/ /g, "")}
                                    >
                                      {type}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div className="addRemove">
                              <button
                                ref={(el) => (buttonsRef.current[index] = el)}
                                onClick={(e) =>
                                  handleSelectionAdd(e, meal._id, lastSunday)
                                }
                                className={`addMeal ${
                                  (currentSelections[lastSunday] || []).some(
                                    (selection) => selection.meal === meal._id
                                  )
                                    ? "hide"
                                    : ""
                                }`}
                              >
                                Add Recipe
                              </button>
                              <p
                                onClick={(e) =>
                                  handleSelectionAdd(e, meal._id, lastSunday)
                                }
                              >
                                +
                              </p>
                              <p className="counter">
                                {(currentSelections[lastSunday] || []).find(
                                  (selection) => selection.meal === meal._id
                                )?.count || 0}
                              </p>
                              <p
                                onClick={(e) =>
                                  handleSelectionRemove(e, meal._id, lastSunday)
                                }
                              >
                                -
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <button className="saveMeals" onClick={handleClick}>
                  Save Meals
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedMeal && (
        <MealModal meal={selectedMeal} onClose={() => setSelectedMeal(null)} />
      )}
      <dialog className="filter" ref={dialogRef} id="PaymentRef">
        <div className="modalTop">
          <span>Processing meals</span>
          <button
            className="close"
            onClick={() => (toggleDialog, navigate("/dashboard"))}
          >
            X
          </button>
        </div>
        <div className="modalBody">
          {loading ? (
            <img
              src="../images/icons/loading.gif"
              alt="loading"
              className="loading"
            />
          ) : (
            <p>{updateMessage}</p>
          )}
        </div>
      </dialog>
    </>
  );
};

export default MyMeals;
