import axios from 'axios';
import { useState, useEffect } from 'react';
import AdminPanel from './adminPanel';
import jsPDF from 'jspdf';

const DeliveriesSummary = () => {
  const [usersByDeliveryDay, setUsersByDeliveryDay] = useState({});
  const [driversData, setDriversData] = useState([]);
   const [orders, setOrders] = useState([]);
    const [meals, setMeals] = useState([]);
    const [users, setUsers] = useState({});
    const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    document.title = "Deliveries Summary";

    // const fetchData = async () => {
    //   try {
    //     // Fetch users, meals, and drivers data
    //     const [usersRes, mealsRes, driversRes] = await Promise.all([
    //       axios.get('/api/auth/allusers'),
    //       axios.get('/api/auth/allmeals'),
    //       axios.get('/api/auth/drivers')
    //     ]);

    //     const usersData = usersRes.data;
    //     const mealsData = mealsRes.data;
    //     setDriversData(driversRes.data);

    //     // Create a mapping of meal IDs to meal details
    //     const mealMap = mealsData.reduce((acc, meal) => {
    //       acc[meal._id] = meal;
    //       return acc;
    //     }, {});

    //       // Replace mealSelections IDs with the corresponding meal details
    //     const usersWithMealDetails = usersData.map(user => ({
    //       ...user,
    //       // Ensure valid meals are selected by checking if the meal exists in mealMap
    //       mealSelections: user.mealSelections
    //         .map(mealId => mealMap[mealId]) // Map IDs to meal objects
    //         .filter(meal => meal) // Filter out undefined meals
    //     }));

    //     // Group users by deliveryDay
    //     const groupedUsers = usersWithMealDetails.reduce((acc, user) => {
    //       const day = user.deliveryDay;
    //       if (!acc[day]) {
    //         acc[day] = [];
    //       }
    //       acc[day].push(user);
    //       return acc;
    //     }, {});

    //     setUsersByDeliveryDay(groupedUsers);
    //   } catch (err) {
    //     console.error('Error fetching data:', err);
    //   }
    // };

    // fetchData();
  }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersRes = await axios.get('/api/auth/orders');
                const mealsRes = await axios.get('/api/auth/allmeals');
                const driversRes = await axios.get('/api/auth/drivers');
                setOrders(ordersRes.data.orders);
                setMeals(mealsRes.data);
                setDrivers(driversRes.data);

                const userPromises = ordersRes.data.orders.map(order => 
                    axios.get(`/api/auth/getuser/${order.userId}`).then(response => response.data)
                );

                const usersArray = await Promise.all(userPromises);
                const usersMap = usersArray.reduce((acc, user) => {
                    acc[user._id] = user;
                    return acc;
                }, {});

                setUsers(usersMap);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

const handleDriverChange = async (orderID, driverName) => {
    try {
        const response = await axios.post(`/api/auth/updateOrderDriver`, {
            orderID,
            driverName
        });

        console.log(response.data);
        setOrders(prevOrders => prevOrders.map(order => {
            if (order._id === orderID) {
                return { ...order, driver: driverName };
            }
            return order;
        }));
    } catch (error) {
        console.error('Error updating driver:', error);
    }
};

  const DelSlotFunc = ({ userSlot }) => {
    console.log(userSlot);
    userSlot = parseInt(userSlot);
    switch (userSlot) {
      case 1:
        return '8:00 - 10:00am';
      case 2:
        return '10:00 - 12:00pm';
      case 3:
      case 4:
        return '12:00 - 6:00pm';
      default:
        return '';
    }
  };

  // Helper function to convert data to CSV format
  const convertToCSV = (data) => {
    const headers = ['Customer Name', 'Order', 'Address', 'Delivery Time', 'Special Instructions', 'Customer Number', 'Driver'];
    const csvRows = [headers.join(',')];

    data.forEach(user => {
      user.mealSelections.forEach((meal, index) => {
        const row = [
          index === 0 ? `${user.firstName} ${user.lastName}` : '',
          meal.title,
          `${user.addressLine1}, ${user.addressLine2}, ${user.area}, ${user.town}`,
          DelSlotFunc({ userSlot: user.deliverTimeSlot }),
          user.specialInstructions || '',
          user.contact,
          driversData.find(driver => driver._id === user.driverId)?.drivername || 'Unassigned'
        ];
        csvRows.push(row.join(','));
      });
    });

    return csvRows.join('\n');
  };

  // Function to download CSV for a specific day
  const downloadCSVByDay = (day) => {
    const dayData = usersByDeliveryDay[day];
    if (!dayData) return;

    const csvData = convertToCSV(dayData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `deliveries_${day}.csv`);
    a.click();
  };

  // Function to download CSV for a specific driver
  const downloadCSVByDriver = (driverId) => {
    const driverData = Object.values(usersByDeliveryDay)
      .flat()
      .filter(user => user.driverId === driverId);

    if (!driverData.length) return;

    const csvData = convertToCSV(driverData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `deliveries_driver_${driverId}.csv`);
    a.click();
  };

  // Function to download PDF for a specific day
  const downloadPDFByDay = (day) => {
    const dayData = usersByDeliveryDay[day];
    if (!dayData) return;

    const doc = new jsPDF();
    doc.setFontSize(12);
    let y = 10;

    dayData.forEach(user => {
      user.mealSelections.forEach((meal, index) => {
        if (index === 0) {
          doc.text(`Customer Name: ${user.firstName} ${user.lastName}`, 10, y);
          doc.text(`Address: ${user.addressLine1}, ${user.area}, ${user.town}`, 10, y + 10);
          doc.text(`Delivery Time: ${DelSlotFunc({ userSlot: user.deliverTimeSlot })}`, 10, y + 20);
          doc.text(`Special Instructions: ${user.specialInstructions || 'None'}`, 10, y + 30);
          doc.text(`Contact: ${user.contact}`, 10, y + 40);
          doc.text(`Driver: ${driversData.find(driver => driver._id === user.driverId)?.drivername || 'Unassigned'}`, 10, y + 50);
        }
        doc.text(`Meal: ${meal.title}`, 10, y + 60);
        y += 80;
        if (y > 280) {
          doc.addPage();
          y = 10;
        }
      });
    });

    doc.save(`deliveries_${day}.pdf`);
  };

  // Function to download PDF for a specific driver
  const downloadPDFByDriver = (driverId) => {
    const driverData = Object.values(usersByDeliveryDay)
      .flat()
      .filter(user => user.driverId === driverId);

    if (!driverData.length) return;

    const doc = new jsPDF();
    doc.setFontSize(12);
    let y = 10;

    driverData.forEach(user => {
      user.mealSelections.forEach((meal, index) => {
        if (index === 0) {
          doc.text(`Customer Name: ${user.firstName} ${user.lastName}`, 10, y);
          doc.text(`Address: ${user.addressLine1}, ${user.area}, ${user.town}`, 10, y + 10);
          doc.text(`Delivery Time: ${DelSlotFunc({ userSlot: user.deliverTimeSlot })}`, 10, y + 20);
          doc.text(`Special Instructions: ${user.specialInstructions || 'None'}`, 10, y + 30);
          doc.text(`Contact: ${user.contact}`, 10, y + 40);
          doc.text(`Driver: ${driversData.find(driver => driver._id === user.driverId)?.drivername || 'Unassigned'}`, 10, y + 50);
        }
        doc.text(`Meal: ${meal.title}`, 10, y + 60);
        y += 80;
        if (y > 280) {
          doc.addPage();
          y = 10;
        }
      });
    });

    doc.save(`deliveries_driver_${driverId}.pdf`);
  };

  return (
    <div className='adminDash'>
      <h1>Deliveries Summary</h1>
      <AdminPanel />

      <div className='adminMainContent'>
          <div>
            <h1>Orders Table</h1>
            <table className='orderTable'>
                <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Order Titles</th>
                        <th>Customer Address</th>
                        <th>Delivery Slot</th>
                        <th>Special Instructions</th>
                        <th>Customer Contact</th>
                        <th>Driver</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => {
                        const user = users[order.userId];
                        return user ? order.mealsByStartDate.map(mealDate => (
                            <tr key={mealDate._id}>
                                <td>{user.firstName} {user.lastName}</td>
                                <td>{mealDate.mealSelections.map(mealId => meals.find(meal => meal._id === mealId)?.title).join(', ')}</td>
                                <td>{user.addressLine1}, {user.street}, {user.area}, {user.town}</td>
                                <td> <DelSlotFunc userSlot={mealDate.deliverTimeSlot} /></td>
                                <td>{user.specialInstructions}</td>
                                <td>{user.contact}</td>
                                <td>
                                   <select
                                      value={order.driver || 'Select Driver'}
                                      onChange={(e) => handleDriverChange(order._id, e.target.value)}
                                  >
                                      <option value="Select Driver" disabled>Select Driver</option>
                                      {drivers.map(driver => (
                                          <option key={driver._id} value={driver.drivername}>{driver.drivername}</option>
                                      ))}
                                  </select>
                                </td>
                            </tr>
                        )) : null;
                    })}
                </tbody>
            </table>
        </div>
        <div>
        <h2>Download by Driver</h2>
        {drivers.map(driver => (
          <div key={driver._id} className='driversDownload'>
            <h3>{driver.drivername}</h3>
            <button onClick={() => downloadCSVByDriver(driver._id)}>Download CSV for {driver.drivername}</button>
            <button onClick={() => downloadPDFByDriver(driver._id)}>Download PDF for {driver.drivername}</button>
          </div>
        ))}
        </div>
      </div>
    </div>
         
  );
};

export default DeliveriesSummary;
