import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminPanel from './adminPanel';

const AllMealsList = () => {
  const [themeals, setThemeals] = useState([]);
  const [mealId, setMealId] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [mealsMessage, setMealsMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const fetchMealsls = async () => {
    const res = axios.get(`/api/auth/allmeals`)
      .then(res => {
        if (res.data) {
          setThemeals(res.data);
          setMealId(res.data._id);
          setMealsMessage('');
        } else {
          setMealsMessage('There are currently no meals available. Please check back later.');
        }
      });
  };

  useEffect(() => {
    fetchMealsls();
  }, []);

  const deleteref = useRef();

  function toggleDialog() {
    if (!deleteref.current) {
      return;
    }
    deleteref.current.hasAttribute("open") ? deleteref.current.classList.add("closing") : deleteref.current.classList.remove("closing");
    deleteref.current.hasAttribute("open") ? document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
    deleteref.current.hasAttribute("open") ? deleteref.current.close() : deleteref.current.showModal();
    if (deleteref.current.hasAttribute("open")) {
      document.querySelector('.bfDelete').style.display = 'block';
      document.querySelector('.afDelete').style.display = 'none';
    }
  }

  const deleteThemeal = async (id) => {
    try {
      await axios.delete(`/api/auth/deletemeal/${mealId}`);
      setThemeals((prevthemeals) => prevthemeals.filter(meal => meal._id !== id));
      fetchMealsls();
      document.querySelector('.bfDelete').style.display = 'none';
      document.querySelector('.afDelete').style.display = 'block';
      setMessage('Meal deleted successfully');
      setMealId('');
    } catch (err) {
      console.error(err);
    }
  };

  const openDeleteDialog = (id) => {
    setMealId(id);
    toggleDialog();
  };

  const filteredMeals = themeals.filter(meal =>
    meal.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className='adminDash'>
        <h1>Meals List</h1>
        <AdminPanel />
        <div className='adminMainContent'>
          <div className='themeals'>
            <input
              type="text"
              placeholder="Search meals..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {mealsMessage}
            {filteredMeals.map((themeal) => (
              <div key={themeal._id} className='themeal'>
                <img src={`/images/meals/${themeal.image}`} alt={themeal._id} />
                <div>{themeal.title}</div>
                <Link to={`/editmeals?id=${themeal._id}`}><button>Edit meal</button></Link>
                <button onClick={() => openDeleteDialog(themeal._id)}>Delete meal</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <dialog className="filter" ref={deleteref} id="deleteref">
        <div className="modalTop">Delete Meal
          <button className="close" onClick={toggleDialog}>X</button>
        </div>
        <div className="modalBody">
          <div className='bfDelete'>
            <p>Are you sure you want to delete this meal?</p>
            <div className='buttonGroup'>
              <button className='cancelBut' onClick={toggleDialog}>Cancel</button>
              <button onClick={deleteThemeal}>Delete</button>
            </div>
          </div>
          <div className='afDelete'>
            {message}
            <button onClick={toggleDialog}>Close</button>
          </div>
        </div>
      </dialog>
    </>
  );
}

export default AllMealsList;
