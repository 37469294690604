import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminPanel from './adminPanel';
import axios from 'axios';

const EditMeal = () => {
    const prefs = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classics','guilt free','chicken','meat','gourmet', 'breakfast']
    
    const [preferences, setPreferences ]= useState([]);
     const [selectedImage, setSelectedImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imgName, setImgName] = useState(null);
    const [file, setFile] = useState(null);
    const [imagchange, setImagchange] = useState(false);
  const [message, setMessage] = useState('');
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mealid = queryParams.get('id');
  const [ingredients, setIngredients] = useState(
        Array.from({ length: 15 }, () => ({ name: '', measurementType: '', quantity: '' }))
    );  
  const navigate = useNavigate();
  const[dataset, setDataset] = useState(
      {
        image: '',
        title: '',
        subtitle: '',
        desc: '',
        ingredients: [],
        allergens: '',
        utensils: '',
        energykj: '',
        energykcal: '',
        protein: '',
        fat: '',
        saturated: '',
        carbs: '',
        ofwhichsugars: '',
        salt: '',
        ins1title: '',
        ins2title: '',
        ins3title: '',
        ins4title: '',
        ins5title: '',
        ins6title: '',
        ins1: '',
        ins2: '',
        ins3: '',
        ins4: '',
        ins5: '',
        ins6: '',
        mealtype: '',
        classic: false,
         
      }
    );

    useEffect(() => {
      document.title = "Edit Meal"

      const getMeal = async () => {
        try {
          const response = await axios.get(`/api/auth/getMeal/${mealid}`)
        .then((response) => {
         
         
          setDataset(response.data);
          console.log(dataset)
        
          setCurrentImage(`/images/meals/${response.data.image}` || response.data.image);
          const str = String(response.data.mealtype);
          const mealtypeArray = str ? str.split(',').map(item => item.trim()) : [];
        setPreferences(mealtypeArray);

          // Merge fetched ingredients with the fixed-length array
      const fetchedIngredients = response.data.ingredients;
      const mergedIngredients = Array.from({ length: 15 }, (_, i) => 
        fetchedIngredients[i] || { name: '', measurementType: '', quantity: '' }
      );
      setIngredients(mergedIngredients);
        }) 
          
        } catch (error) {
          console.error(`Error fetching data: ${error}`);
        }
      }




     

      getMeal();
    },[mealid])

 const handleChange = (e) => {
     const { name, value, checked, type } = e.target;
    

      setDataset((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));

   
  };

  const handlepreferenceChange = (e, index) => {
      if(e.target.checked){
        setDataset((prevData) => 
        ({...prevData, mealtype:e.target.value }))
        setPreferences(prevState => [...prevState, e.target.value])
      }else{
        var filteredArray = preferences.filter(preferences => preferences !== e.target.value)
        setPreferences(prevState =>filteredArray)
      }
    }

const handleIngChange = (index, event) => {
    const { name, value } = event.target;

    setIngredients(prevIngredients => {
        const newIngredients = [...prevIngredients];
        newIngredients[index] = { ...newIngredients[index], [name]: value };

        // Update the dataset with the new ingredients array
        setDataset(prevData => ({
            ...prevData,
            ingredients: newIngredients
        }));

        console.log(newIngredients);
        return newIngredients;
    });
};

    

const handleImageChange = (event) => {
    // setSelectedImage(event.target.files[0]);
    // const imageName = event.target.files[0].name;
    // console.log(imageName)
    // setImgName(imageName)
    setFile(event.target.files[0]);
    const { name, value } = event.target;
    setImagchange(true)
    
    
    setCurrentImage(URL.createObjectURL(event.target.files[0]));
  };

 
  const handleSubmit = (e) => {
      e.preventDefault();
      if(imagchange){
        handleSubmitImage(e)
      }else{
        handleSubmitData(e)
      }
 
 }

  const handleSubmitImage = async (e) => {
    e.preventDefault();
     const formData = new FormData();
     formData.append('image', file);
              
    try {
      const response = await axios.post('api/auth/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      
      setImgName(response.data.filename);
     
     
      
      
      
      alert('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading the image', error);
      alert('Error uploading the image');
    }
  };
  
  useEffect(() => {
    if(imgName){
      
    
     setDataset((prevData) => ({
      ...prevData,
      image: imgName,
    }));
    
    handleSubmitData();
}
  } ,[imgName,dataset.image])

  const handleSubmitData = async () => {
    const filteredData = {};

    // Loop through dataset and only add defined properties to filteredData
    for (let key in dataset) {
        if (dataset[key] !== undefined && dataset[key] !== null && dataset[key] !== '') {
            filteredData[key] = dataset[key];
        }
    }

    // Manually add preferences to filteredData
    filteredData.mealtype = preferences;
     console.log("Filtered data to send:", filteredData);

    try {
   
      const response = await axios.put(`api/auth/updateMeal/${mealid}`,filteredData);
       navigate("/mealsList");
      
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };




  const MealTypes = () => prefs.map((category, index) => (
    <div key={index} className='prefcheck'>
    
    <input type="checkbox" value={category}  id={category} name="mealType" onChange={handlepreferenceChange}  checked={preferences.includes(category)}  /> <label className={`preference ${category.replace(/ /g,'')}`}   htmlFor={category}>{category}</label> 
    </div>
  ));

  return (
    <>
    <div className='adminDash'>
      <h1>Edit Meal</h1>
       <AdminPanel />

       <div className='adminMainContent userForm'>
    
    <form onSubmit={handleSubmit}>
      <fieldset className='row2'>
    <div className='image'>
         {currentImage && (
        <img src={currentImage} alt="Current Image" id="current-image" />
      )}
    </div>
    <h4>Change Image</h4>
    <input type="file" name='image' accept="image/*" onChange={handleImageChange} />
    </fieldset>
    <fieldset className='col2'>
    <div>
      <label>Meal Title</label>
      <input type='text' name='title' onChange={handleChange} value={dataset.title} />

      </div>
     <div>
      <label>Meal Subtitle</label>
      <input type='text' name='subtitle' value={dataset.subtitle}  onChange={handleChange} />
      </div>
    <div><label>Meal Description</label><br/><textarea name='desc'  type='text' value={dataset.desc}  onChange={handleChange}></textarea>
    <div><label>Classic meal</label><input type='checkbox' name='classic'  checked={dataset.classic}  onChange={handleChange} /></div>
    </div>
    </fieldset>
  
<fieldset>
  <h3>Ingredients</h3>
  {ingredients.map((ingredient, index) => (
    <div key={index} className="ingredient">
      <label>
        Ingredient {index + 1}:
        <input
          type="text"
          name="name"
          value={ingredient.name}
          onChange={(event) => handleIngChange(index, event)}
        />
      </label>
      <label>
        Measurement Type:
        <select
          name="measurementType"
          value={ingredient.measurementType}
          onChange={(event) => handleIngChange(index, event)}
        >
          <option value="">Select</option>
          <option value="pieces">Pieces</option>
          <option value="grams">Grams</option>
        </select>
      </label>
      <label>
        Quantity:
        <input
          type="number"
          name="quantity"
          value={ingredient.quantity}
          onChange={(event) => handleIngChange(index, event)}
        />
      </label>
    </div>
  ))}
</fieldset>

            <fieldset>
              <div><label>Allergens</label><br/><textarea  name='allergens' type='text' value={dataset.allergens}  onChange={handleChange}></textarea></div>
    </fieldset>
    <fieldset className='col1'>
    <div><label>Utensils</label><br /><textarea name='utensils'  type='text' value={dataset.utensils}  onChange={handleChange}></textarea></div>
    </fieldset>
    <fieldset className='col2'>
    <div><label>energykj</label><input name='energykj'  type='text' value={dataset.energykj}  onChange={handleChange}></input></div>
    <div><label>energykcal</label><input name='energykcal'  type='text' value={dataset.energykcal}  onChange={handleChange}></input></div>
    <div><label>protein</label><input  type='text' name='protein' value={dataset.protein}  onChange={handleChange}></input></div>
    <div><label>fat</label><input  type='text' name='fat' value={dataset.fat}  onChange={handleChange}></input></div>
    <div><label>saturatedfat</label><input name='saturated' type='text' value={dataset.saturated}  onChange={handleChange}></input></div>
    <div><label>carbohydrates</label><input name='carbs' type='text' value={dataset.carbs}  onChange={handleChange}></input></div>
    <div><label>Of which sugars</label><input name='ofwhichsugars'  type='text' value={dataset.ofwhichsugars}  onChange={handleChange}></input></div>
    <div><label>Sodium</label><input  type='text' name='salt' value={dataset.salt}  onChange={handleChange}></input></div>
    </fieldset>

    <fieldset className='col3'>
    <div><label>Step 1 Instruction Title</label><br /><input name='ins1title'  type='text' value={dataset.ins1title}  onChange={handleChange}></input></div>
    <div><label>Step 1 Instruction</label><br /><textarea name='ins1'  type='text' value={dataset.ins1}  onChange={handleChange}></textarea></div>

    <div><label>Step 2 Instruction Title</label><br /><input name='ins2title'  type='text' value={dataset.ins2title}  onChange={handleChange}></input></div>
    <div><label>Step 2 Instruction</label><br /><textarea name='ins2'  type='text' value={dataset.ins2}  onChange={handleChange}></textarea></div>

    <div><label>Step 3 Instruction Title</label><br /><input name='ins3title'  type='text' value={dataset.ins3title}  onChange={handleChange}></input></div>
    <div><label>Step 3 Instruction</label><br /><textarea name='ins3'  type='text' value={dataset.ins3}  onChange={handleChange}></textarea></div>

    <div><label>Step 4 Instruction Title</label><br /><input name='ins4title'  type='text' value={dataset.ins4title}  onChange={handleChange}></input></div>
    <div><label>Step 4 Instruction</label><br /><textarea name='ins4'  type='text' value={dataset.ins4}  onChange={handleChange}></textarea></div>

    <div><label>Step 5 Instruction Title</label><br /><input name='ins5title'  type='text' value={dataset.ins5title}  onChange={handleChange}></input></div>
    <div><label>Step 5 Instruction</label><br /><textarea name='ins5'  type='text' value={dataset.ins5}  onChange={handleChange}></textarea></div>

    <div><label>Step 6 Instruction Title</label><br /><input name='ins6title'  type='text' value={dataset.ins6title}  onChange={handleChange}></input></div>
    <div><label>Step 6 Instruction</label><br /><textarea name='ins6'  type='text' value={dataset.ins6}  onChange={handleChange}></textarea></div>
      </fieldset>
      <fieldset className='col4'>
   <div><h3>Meal Types</h3><MealTypes /></div>
   </fieldset>
   <div className='buttonSection'>
   <button type='submit'>Submit Changes</button>
   <Link to="/mealsList"> <button>Cancel</button></Link>
   </div>
   </form>
    </div>
    </div>
    </>
  )
}

export default EditMeal