function calculateUpcomingDay(targetDay) {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let isToday = false;

  let daysToAdd;
  if (targetDay === 'wednesday') {
    daysToAdd = (3 - dayOfWeek + 7) % 7;
  } else if (targetDay === 'friday') {
    daysToAdd = (5 - dayOfWeek + 7) % 7;
  } else if (targetDay === 'sunday') {
    daysToAdd = (6 - dayOfWeek + 7) % 7;
  } else {
    return null;
  }

  // Ensure days_to_add is positive
  if (daysToAdd <= 0) {
    isToday = true
    daysToAdd += 6;
  }

  // Calculate the upcoming date starting from tomorrow
  const upcomingDate = new Date(today);
  upcomingDate.setDate(upcomingDate.getDate() + daysToAdd + 1);

  // Check if the upcoming date is more than 48 hours away from today
  const isMoreThan48HoursAway = (upcomingDate.getTime() - today.getTime()) > (48 * 60 * 60 * 1000);

  return {
    upcomingDate,
    isMoreThan48HoursAway,
    isToday
  };
}

export default calculateUpcomingDay;