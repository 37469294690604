import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Preferences = ({ formData, setFormData, inRegister, setInRegister }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log(formData);
    if (!inRegister.includes('1')) {
      navigate('/boxes');
    }

    setInRegister(['0', '1', '2']);
  }, []);

  const handleChange = (e) => {
    const ischecked = e.target.checked;
    const prefValue = e.target.value;

    if (prefValue === 'none') {
      setFormData((prevData) => ({
        ...prevData,
        preferences: [],
      }));

      document.querySelectorAll('.prefBox input').forEach((box) => {
        if (ischecked) {
          if (box.value !== 'none') {
            box.checked = false;
          }
        }
      });
    } else {
      document.querySelectorAll('.prefBox input').forEach((box) => {
        if (ischecked && box.value === 'none') {
          box.checked = false;
        }
      });

      setFormData((prevData) => {
        const newPreferences = prevData.preferences || []; // Fallback to empty array if undefined
        if (newPreferences.includes(prefValue)) {
          return {
            ...prevData,
            preferences: newPreferences.filter((preference) => preference !== prefValue),
          };
        } else {
          return {
            ...prevData,
            preferences: [...newPreferences, prefValue],
          };
        }
      });
    }
  };

  const handleNext = () => {
    localStorage.setItem('userdata', JSON.stringify(formData));
    navigate('/meals');
  };

  const prefs = ['family friendly', 'kid friendly', 'low carb', 'vegetarian', 'pescatarian', 'calorie smart', 'classics', 'guilt free', 'chicken', 'meat', 'gourmet', 'breakfast'];

  return (
    <>
      <div className='wrapper prefs'>
        <div className="container">
          <h1>Preferences</h1>
          <h2>Select your preferences</h2>
          <div className="boxesPeople">
            <div className="checkboxes-group">
              {prefs.map((category, index) => (
                <div key={index} className='prefBox testCheck'>
                  <input type="checkbox" value={category} id={category} name="preferences" onChange={handleChange} checked={formData.preferences && formData.preferences.includes(category)} />
                  <label className={`preference ${category.replace(/ /g, '')}`} htmlFor={category}>{category}</label>
                </div>
              ))}
              <div className='prefBox'>
                <input type="checkbox" value="none" id="none" name="preferences" onChange={handleChange} className='none' />
                <label className="none" htmlFor="none">None</label>
              </div>
            </div>
          </div>
          <button className="nextButton" onClick={handleNext}>Next</button>
        </div>
      </div>
    </>
  );
};

export default Preferences;
