import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminPanel from './adminPanel';

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [message, setMessage] = useState('');
  const driverDialogRef = useRef();

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const res = await axios.get('/api/auth/drivers');
      setDrivers(res.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const deleteDriver = async (id) => {
    try {
      await axios.delete(`/api/auth/deleteDriver/${id}`);
      setDrivers(prevDrivers => prevDrivers.filter(driver => driver._id !== id));
      setMessage('Driver deleted successfully');
    } catch (error) {
      console.error('Error deleting driver:', error);
      setMessage('Failed to delete driver');
    }
  };

  const toggleDialog = (dialogRef) => {
    if (!dialogRef.current) return;
    dialogRef.current.hasAttribute("open") ? dialogRef.current.close() : dialogRef.current.showModal();
  };

  const openDriverDialog = () => {
    setMessage('');
    toggleDialog(driverDialogRef);
  };

  const createDriver = async (event) => {
    event.preventDefault();
    const driverName = event.target.driverName.value;
    const driverNumber = event.target.driverNumber.value;
    try {
      await axios.post('/api/auth/addDriver', { driverName, driverNumber });
      fetchDrivers();
      setMessage('Driver created successfully');
      toggleDialog(driverDialogRef);
    } catch (error) {
      console.error('Error creating driver:', error);
      setMessage('Failed to create driver');
    }
  };

  return (
    <div className='adminDash'>
      <h1>Drivers</h1>
      <AdminPanel />
      <div className='adminMainContent userList'>
        <div className="userTable">
          <button  className='emailAll' onClick={openDriverDialog}>Create New Driver</button>
          <table className='userListTable'>
            <thead>
              <tr>
                <th>Driver Name</th>
                <th>Driver Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {drivers.map(driver => (
                <tr key={driver._id}>
                  <td>{driver.drivername}</td>
                  <td>{driver.driverno}</td>
                  <td>
                    <button onClick={() => deleteDriver(driver._id)}>Delete Driver</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
              
      <dialog className="filter" ref={driverDialogRef} id="driverDialogRef">
        <div className="modalTop">Create New Driver <button className="close" onClick={() => toggleDialog(driverDialogRef)}>X</button></div>
        <div className="modalBody">
          <form onSubmit={createDriver}>
            <label htmlFor="driverName">Driver Name:</label>
            <input type="text" name="driverName" id="driverName" required />
            <label htmlFor="driverNumber">Driver Number:</label>
            <input type="number" name="driverNumber" id="driverNumber" required />    
            <button type="submit">Create Driver</button>
          </form>
        </div>
      </dialog>
      </div>
  );
};

export default Drivers; 