import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import AdminPanel from './adminPanel';

const AdminDash = () => {
  
//    const [users, setUsers] = useState([])
//    const [usersLength, setUsersLength] = useState('')
//    const [totalMealSelections, setTotalMealSelections] = useState(0);
//    const [totalOrders, setTotalOrders] = useState([0]);
 
//    const [counts, setCounts] = useState({});
// const [today, setToday] = useState('');
//   const [todayCount, setTodayCount] = useState(0);

//    useEffect(() => {
    
  
//        const res =  axios.get(`/api/auth/allusers`)
//         .then(res => {
//         const usersData = res.data;
//         setUsers(usersData);
//         setUsersLength(usersData.length);

//         // Calculate total meal selections
//         // const totalMeals = usersData.reduce((acc, user) => acc + (user.mealSelections ? user.mealSelections.length : 0), 0);
//         // setTotalMealSelections(totalMeals);
//       })
//       .catch(err => {
//         console.error('Error fetching users:', err);
//       });
//   }, []);

//  useEffect(() => {
//         const fetchOrders = async () => {
//             try {
//                 const response = await axios.get('/api/auth/orders');
//                 setTotalOrders(response.data.orders.length);
//                 setTotalMealSelections(response.data.totalMealSelections);
//             } catch (error) {
//                 console.error('Error fetching orders:', error);
//             }
//         };

//         fetchOrders();
//     }, []);
//   useEffect(() => {
//     const countTowns = (arr) => {
     
//       const countsMap = new Map();
//       arr.forEach(obj => {
//         const town = obj.town;
//         countsMap.set(town, (countsMap.get(town) || 0) + 1);
//       });
//       const countsObject = Object.fromEntries(countsMap);
//       setCounts(countsObject);
//     };

//     countTowns(users);
  
//   }, [users]);

  

//    useEffect(() => {
//     const getDayOfWeek = () => {
//       const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//       const today = new Date();
//       const dayIndex = today.getDay();
//       return daysOfWeek[dayIndex];
//     };

//     const countTodayDeliveries = (arr, today) => {
//       return arr.reduce((count, obj) => {
//         return obj.deliveryDay === today ? count + 1 : count;
//       }, 0);
//     };

//     const todayDay = getDayOfWeek();
//     setToday(todayDay);

//     const count = countTodayDeliveries(users, todayDay);
//     setTodayCount(count);
//   }, [users]);


  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [townCounts, setTownCounts] = useState({});
  const [totalMeals, setTotalMeals] = useState(0);
  const [todayDeliveries, setTodayDeliveries] = useState(0);

    useEffect(() => {
  const fetchData = async () => {
    try {
      console.log("Starting data fetch...");
      const ordersRes = await axios.get('/api/auth/orders');
      console.log("Orders fetched:", ordersRes.data);

      const usersRes = await axios.get('/api/auth/allusers');
      console.log("Users fetched:", usersRes.data);
      
      setOrders(ordersRes.data.orders);
      setUsers(usersRes.data);
      
      const townCountMap = {};
      let mealCount = 0;
      let todayCount = 0;

      const today = new Date().toISOString().split('T')[0];

      ordersRes.data.orders.forEach(order => {
        const user = usersRes.data.find(user => user._id === order.userId);
        if (!user) {
          console.error(`User not found for order: ${order._id}, userId: ${order.userId}`);
        } else {
          const town = user.town || 'Unknown';
          if (!townCountMap[town]) {
            townCountMap[town] = 0;
          }
          townCountMap[town]++;
        }

        order.mealsByStartDate.forEach(meal => {
          mealCount += meal.mealSelections.length;
          try {
            console.log(`Processing meal with delivery date: ${meal.deliveryDate}`);
            const [year, month, day] = meal.deliveryDate.split('-');
            const mealDeliveryDate = new Date(year, month - 1, day).toISOString().split('T')[0];
            if (mealDeliveryDate === today) {
              todayCount++;
            }
          } catch (error) {
            console.error(`Invalid date format for meal: ${meal.deliveryDate}`, error);
          }
        });
      });

      setTownCounts(townCountMap);
      setTotalMeals(mealCount);
      setTodayDeliveries(todayCount);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  fetchData();
}, []);




  // return (
  //   <>
   
  //   <div className='adminDash'>
  //      <h1>Admin Dashboard</h1>
  //      <AdminPanel />
       
  //      <div className='adminMainContent '>
  //       <div className='dashboard'>
  //       <div className='noOfUsers dashpanels'><p>Current number of Users:<br/><span>{usersLength}</span></p></div>
  //       <div className='noOfMeals dashpanels'><p>Number of meals on order:<br/><span>{totalMealSelections}</span></p></div>
  //       <div className='noOfOrders dashpanels'><p>Number of orders<br/><span>{totalOrders}</span></p></div>
       
  //       <div className='deliveriesToday dashpanels'><p>Deliveries today:<br/><span> {todayCount}</span></p></div>
  //      </div>
  //        <div className='townPanel'>
  //     <h2>Cities currently delivering to</h2>
  //     <ul>
  //       {Object.entries(counts).map(([town, count]) => (
  //         <li key={town}>
  //          <strong> {town}:</strong> Number of deliveries: {count}
  //         </li>
  //       ))}
  //     </ul>
  //   </div>

     
       
       
  //      </div>

      

  //   </div>
  //   </>
  // )
  return (
    <div className='adminDash'>
        <h1>Admin Dashboard</h1>
       <AdminPanel />
       
       <div className='adminMainContent '>
         <div className='dashboard'>
    
      
      <div className='noOfUsers dashpanels'>Number of Users: <br /><span>{users.length}</span></div>
      <div className='dashpanels noOfMeals'>Number of Orders:<br /> <span>{orders.length}</span></div>
      <div className='dashpanels noOfOrders'>Total Meals on order:<br /> <span>{totalMeals}</span></div>
      <div className='dashpanels'>Today's Deliveries:<br /> <span>{todayDeliveries}</span></div>
      </div>
          <div className='townPanel'>
      <div className=''>Deliveries to:
      <ul>
        {Object.keys(townCounts).map(town => (
          <li key={town}>{town}: {townCounts[town]} order(s)</li>
        ))}
      </ul>
      </div>
   
  </div>
       </div>

    </div>
  );

}

export default AdminDash