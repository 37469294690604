
import axios from 'axios';
import { useState, useEffect } from 'react';
import AdminPanel from './adminPanel';
import jsPDF from 'jspdf';

function Prepsummary() {
 const [customerMeals, setCustomerMeals] = useState([]);
  const [delslotTime, setDelslotTime] = useState('');
  const [customerMealsByDay, setCustomerMealsByDay] = useState({});
  const [orders, setOrders] = useState([]);
    const [meals, setMeals] = useState([]);
    const [users, setUsers] = useState({});

  useEffect(() => {
    document.title = "Prep Summary";

  }, []);

  // const fetchData = async () => {
  //   try {
  //     // Fetch users and meals data
  //     const [usersRes, mealsRes] = await Promise.all([
  //       axios.get('/api/auth/allusers'),
  //       axios.get('/api/auth/allmeals'),
  //     ]);

  //     const usersData = usersRes.data;
  //     const mealsData = mealsRes.data;
  //     console.log(mealsData);
  //     // Create a mapping of meal IDs to meal details
  //     const mealMap = mealsData.reduce((acc, meal) => {
  //       acc[meal._id] = meal;
  //       return acc;
  //     }, {});

  //     // Map user data to include meal details and ingredients as list
  //     const customerMealsData = usersData.map(user => {
  //       // Check if the mealSelections contain valid IDs that match mealsData
  //       const mappedMeals = user.mealSelections.map(mealId => {
  //         const meal = mealMap[mealId];
  //         if (!meal) {
  //           console.error(`Meal with ID ${mealId} not found in mealsData`);
  //         }
  //         return meal; // Will be undefined if no match found
  //       });

  //       return {
  //         name: `${user.firstName} ${user.lastName}`,
  //         address: `${user.addressLine1} ${user.addressLine2}, ${user.street}, ${user.area}, ${user.town}`,
  //         noMeals: user.noMeals,
  //         deliverySlot: user.deliverTimeSlot,
  //         deliveryDay: user.deliveryDay,
  //         meals: mappedMeals.filter(meal => meal !== undefined) // Filter out any undefined meals
  //       };
  //     });

  //     // Group customer meals by delivery day
  //     const groupedByDay = customerMealsData.reduce((acc, customer) => {
  //       const day = customer.deliveryDay;
  //       if (!acc[day]) {
  //         acc[day] = [];
  //       }
  //       acc[day].push(customer);
  //       return acc;
  //     }, {});

  //     setCustomerMealsByDay(groupedByDay);
  //   } catch (err) {
  //     console.error('Error fetching data:', err);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersRes = await axios.get('/api/auth/orders');
                const mealsRes = await axios.get('/api/auth/allmeals');
                setOrders(ordersRes.data.orders);
                setMeals(mealsRes.data);

                const userPromises = ordersRes.data.orders.map(order => 
                    axios.get(`/api/auth/getuser/${order.userId}`).then(response => response.data)
                );

                const usersArray = await Promise.all(userPromises);
                const usersMap = usersArray.reduce((acc, user) => {
                    acc[user._id] = user;
                    return acc;
                }, {});

                setUsers(usersMap);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const groupOrdersByDateAndUser = (orders, meals) => {
        const grouped = {};

        orders.forEach(order => {
            order.mealsByStartDate.forEach(mealDate => {
                const date = mealDate.deliveryDate;
                if (!grouped[date]) {
                    grouped[date] = {};
                }

                const user = users[order.userId];
                if (user) {
                    if (!grouped[date][user.email]) {
                        grouped[date][user.email] = [];
                    }

                    mealDate.mealSelections.forEach(mealId => {
                        const meal = meals.find(m => m._id === mealId);
                        if (meal) {
                            grouped[date][user.email].push({
                                title: meal.title,
                                servings: mealDate.servings,
                                ingredients: meal.ingredients
                            });
                        }
                    });
                }
            });
        });

        return grouped;
    };

    const groupedOrders = groupOrdersByDateAndUser(orders, meals);

  // Helper function to convert data to CSV format
  const convertToCSV = (data) => {
    const headers = [
      'Delivery Day',
      'Customer Name',
      'Address',
      'Number of Servings',
      'Delivery Slot',
      'Meal Title',
      'Ingredients'
    ];

    const csvRows = [headers.join(',')];

    Object.keys(data).forEach(day => {
      data[day].forEach(customer => {
        customer.meals.forEach((meal, index) => {
          const row = [
            index === 0 ? day : '', // Only show day for the first meal of this customer
            index === 0 ? customer.name : '', // Only show name for the first meal of this customer
            index === 0 ? customer.address : '', // Only show address for the first meal
            index === 0 ? customer.noMeals : '', // Only show servings once
            index === 0 ? customer.deliverySlot : '', // Only show delivery slot once
            meal.title,
           
          ];
          csvRows.push(row.join(','));
        });
      });
    });

    return csvRows.join('\n');
  };

  // Function to download CSV
  const downloadCSV = (day) => {
    const csvData = convertToCSV({ [day]: customerMealsByDay[day] });
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `Preparation_Summary_by_day.csv`);
    a.click();
  };

  // Function to download PDF
   const downloadPDF = (day) => {
    const dayData = customerMealsByDay[day];
    if (!dayData) return;

    const doc = new jsPDF();
    doc.setFontSize(12);

    let y = 20; // Y-coordinate for the text

    dayData.forEach(customer => {
      customer.meals.forEach((meal, index) => {
        if (index === 0) {
          // Only show customer details for the first meal
          doc.text(`Delivery Day: ${day}`, 10, y);
          doc.text(`Customer Name: ${customer.name}`, 10, y + 10);
          doc.text(`Address: ${customer.address}`, 10, y + 20);
          doc.text(`Number of Servings: ${customer.noMeals}`, 10, y + 30);
          doc.text(`Delivery Slot: ${customer.deliverySlot}`, 10, y + 40);
        }
        doc.text(`Meal Title: ${meal.title}`, 10, y + 50);

        doc.text('Ingredients:', 10, y + 60);
        const ingredientsList = meal.ingredients.split(',').map(ingredient => `- ${ingredient.trim()}`);
        ingredientsList.forEach((ingredient, i) => {
          doc.text(ingredient, 10, y + 70 + i * 10); // Display ingredients as a list
        });

        y += 50 + ingredientsList.length * 10; // Update Y position for the next meal
        if (y > 200) {
          doc.addPage();
          y = 10;
        }
      });
    });

    doc.save(`customer_meals_${day}.pdf`);
  };


  const DelSlotFunc = ({userSlot}) => {
       
        userSlot = parseInt(userSlot)    
         
    switch (userSlot) { // 8-10 am 
      case 1:
        return '8:00 - 10:00am'
      case 2:
        return  '10:00 - 12:00am'
      case 3:
        return  '12:00 - 6:00pm'
      case 4:
        return  '12:00 - 6:00pm'
      }

    }

  return (
        <div className='adminDash'>
       <h1>Preparation Summary</h1>
       <AdminPanel />
       
       <div className='adminMainContent '>
        <div className='dashboard orderSummary'>
       <div>
            
            {Object.keys(groupedOrders).map(date => (
                <div key={date}>
                    <h2>Date: {date}</h2>
                  <table className='prepTable'>
        <thead>
          <tr>
          
            <th>Meal Title</th>
            <th>Servings</th>
           <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedOrders[date]).map(email => (
            groupedOrders[date][email].map((meal, index) => (
              <>
              <tr key={`${email}-${index}`}>
               
                <td>{meal.title}</td>
                <td>{meal.servings}</td>
                <td></td>
                </tr>
                <tr>
                <td colSpan={2}>
                  <table className='ingsTable'>
                    <thead>
                    <tr>
                      <th colSpan={3}>Ingredients</th>
                     </tr>
                     
          <tr>
          
            <th>Ingredient Name</th>
            <th>Quantity</th>
            <th>Quantity type</th>
          </tr>
        </thead>
        <tbody>
                    {meal.ingredients.map((ingredient, i) => (
                      ingredient.name && ingredient.quantity && ingredient.measurementType && (
                    <tr  key={i}>
                      <td>
                     {ingredient.name}
                     </td>
                     <td>
                    {ingredient.quantity}
                    </td>
                    <td>
                    {ingredient.measurementType}
                    </td>
                    </tr>
                      )
                    ))}
                    </tbody>
                        </table>
                 
                 
                </td>
              </tr>
              </>
            ))
          ))}
          
        </tbody>
      </table>
                </div>
            ))}
        </div>
      </div>
    </div>
         </div>

   
  )
}

export default Prepsummary