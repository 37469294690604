import React from 'react';

const WhatsAppLink = ({ phoneNumber, message }) => {
  const encodedMessage = encodeURIComponent(message);
  const link = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      Send WhatsApp Message
    </a>
  );
};

export default WhatsAppLink;
